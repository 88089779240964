/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Button, Subtitle, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Výsadba a řez stromků"} description={"Výchozí stránka s výčtem hlavních služeb"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim3 --anim-s4 pt--20" anim={"3"} animS={"4"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":2704}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":2244}}>
              
              <Image alt={"užitečná zahrada"} src={"https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":1882}} srcSet={"https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=2000x_.jpg 2000w"}>
              </Image>

              <Title className="title-box title-box--center fs--30" content={"Užitečná zahrada<br>Zahrada pro potěšení i k užitku<br>"}>
              </Title>

              <Text className="text-box text-box--center ff--1 fs--20 w--500" style={{"maxWidth":1743}} content={"<i><font color=\"rgba(74,74,74,1)\">Když si chcete vypěstovat vlastní ovoce a nespoléhat na fádní nabídku marketů ... <br>Když chcete ze zahrady nejen potěšení ...<br>Když chce poznat nové chutě přírody, nebo i ty staré, co už těžko objevíte v obchodech..<br>Když chcete vlastní ovoce čerstvé na stůl, nebo na koláč, zavařit, odštavňovat, moštovat, džemovat, sušit, zamrazit, vypálit, ... , musíme nejprve vysadit.<br>Realizujeme služby zejména v okrese Frýdek - Místek i mimo něj, do cca 50 km z Frýdlantu n.O. ...</font></i><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--50" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19015/fc7ea86c29a840e9bac403e3d66704f8_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/fc7ea86c29a840e9bac403e3d66704f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/fc7ea86c29a840e9bac403e3d66704f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/fc7ea86c29a840e9bac403e3d66704f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/fc7ea86c29a840e9bac403e3d66704f8_s=1400x_.jpg 1400w"}>
              </Image>

              <Title className="title-box" content={"Výsadba stromků<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Podzimní nebo jarní výsadba ovocných stromků. V rozsahu minimálně 3 ks ovocných stromků nebo keřů, podle požadavků zákazníka a pěstitelských možností. Využijte služby Poradenství, pokud nevíte, co kam chcete zasadit.<br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/rez"} content={"Detailněji"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19015/31b26b49970e4f6f8fbcb21b7019eda9_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/31b26b49970e4f6f8fbcb21b7019eda9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/31b26b49970e4f6f8fbcb21b7019eda9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/31b26b49970e4f6f8fbcb21b7019eda9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/31b26b49970e4f6f8fbcb21b7019eda9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/31b26b49970e4f6f8fbcb21b7019eda9_s=2000x_.jpg 2000w"}>
              </Image>

              <Title className="title-box" content={"Poradenství"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Nechte si poradit, co je dostupné a čemu se může ve Vaší zahradě dařit a kam stromky a keře umístit. Vytipujeme vhodné druhy ovocných stromků, probereme vhodná stanoviště.<br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/poradenstvi"} content={"Detailněji"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19015/a072e5f693a74d718d4c2715d0d7bdb5_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/a072e5f693a74d718d4c2715d0d7bdb5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/a072e5f693a74d718d4c2715d0d7bdb5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/a072e5f693a74d718d4c2715d0d7bdb5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/a072e5f693a74d718d4c2715d0d7bdb5_s=1400x_.jpg 1400w"}>
              </Image>

              <Title className="title-box" content={"Řez ovocných stromků"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Letní, zimní nebo zmlazovací řez. <br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/rez"} content={"Detailněji"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1bh6gky --style3 --full pb--30" anim={""} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/ee1a6acd0f1b46e48a5092946b37400f_bri=115__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"}>
              
              <Title className="title-box fs--102 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Výsadba<br>&amp; řez ovocných stromků<br></span>"}>
              </Title>

              <Button className="btn-box" use={"page"} href={"/kontakt"} target={"_blank"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-mkzar2 pb--60 pt--60" name={"kontakt"} layout={"l1"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/93db46256b4a4270b9a4cba514ebe680_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--10 pt--10">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Potřebujete poradit?<br></span>"}>
              </Title>

              <Button className="btn-box" url={"http://uzitecnazahrada/kontakt"} use={"url"} href={"http://uzitecnazahrada/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4 pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} border={"4"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Výsadba, poradenství<br>&amp; řez ovocných stromků<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Hlavní 68, Frýdlant nad Ostravicí</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 777 707 360<br>info@uzitecnazahrada.cz"}>
              </Text>

              <Text className="text-box" content={"IČ: 26787792<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--08 pt--08" menu={true} name={"x28415oot2"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" target={""} content={"Položka"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}